import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RenderWellnessQuestions, RenderQuestionContainer, QuestionContainer, CheckMarkContainer, CheckMark, OptionContainer} from "../../containers/WellnessAssessment/WellbeingDashboard/styles";
import { withTranslation } from 'react-i18next';

class QuestionComponent extends Component {
  renderOptions = (options, section) => {
    const{color, saveData, addedAnswers} = this.props;
    return options.map((answer, index) => (<OptionContainer
      key={index}
      onClick={() => saveData(section.question_id, answer.answer_id, section.is_multiple_answers, section.point_status ? answer.point : 0)}
      isActive={addedAnswers && addedAnswers.find((obj)=>obj===answer.answer_id)}
      bgColor={color}
    >
      <div>
        <CheckMarkContainer>
          <CheckMark bgColor={color}/>
        </CheckMarkContainer>
      </div>
      <div>{this.props.t(answer.answer)}</div>
    </OptionContainer>
    ))
  };
  renderQuestions = (questionList, questionNo) => (
    <RenderWellnessQuestions>
      <RenderQuestionContainer>
        <QuestionContainer>
          <div>{`Q${questionNo}. ${this.props.t(questionList.question)}`}</div>
        </QuestionContainer>
        {this.renderOptions(questionList.answers, questionList)}
      </RenderQuestionContainer>
    </RenderWellnessQuestions>
  );
  render() {
    const { section, questionNo } = this.props;
    return (
      <div>
        {this.renderQuestions(section, questionNo)}
      </div>
    );
  }
}

QuestionComponent.propTypes = {
  section: PropTypes.object.isRequired,
  addedAnswers: PropTypes.string,
  saveData: PropTypes.func.isRequired,
  color: PropTypes.string,
  questionNo: PropTypes.number,
  t: PropTypes.func
};

export default ((withTranslation())(QuestionComponent));
